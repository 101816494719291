import React from 'react';
import './SiteInfo.css';
import config from '../../config/site'


const siteinfo = () => (
    <div className="siteinfo">

      <div className="whatwedo">
        {/* <ul className="list-unstyled"> */}
          <span className="listitems">Paint Nights</span>
          <span className="listitems">Commissions</span>
          <span className="listitems">Private Lessons</span>
          <span className="listitems">Classes</span>
          <span className="listitems">Calligraphy</span>
        {/* </ul> */}
      </div>

    </div>
);

export default siteinfo;
