import React, { Component } from "react";
import "./App.css";
import config from "./config/site";
import Gallery from "./components/Gallery/Gallery";
import SiteInfo from "./components/SiteInfo/SiteInfo";
import logo from "./static/logo/vivid_hues_logo.png";
import instalogo from "./static/logo/instalogo.svg";

import thumb01 from "./static/images/thumbs/01.jpg";
import full01 from "./static/images/fulls/01.jpg";
import thumb02 from "./static/images/thumbs/02.jpg";
import full02 from "./static/images/fulls/02.jpg";
import thumb03 from "./static/images/thumbs/03.jpg";
import full03 from "./static/images/fulls/03.jpg";
import thumb04 from "./static/images/thumbs/04.jpg";
import full04 from "./static/images/fulls/04.jpg";
import thumb05 from "./static/images/thumbs/05.jpg";
import full05 from "./static/images/fulls/05.jpg";
import thumb06 from "./static/images/thumbs/06.jpg";
import full06 from "./static/images/fulls/06.jpg";
import thumb07 from "./static/images/thumbs/07.jpg";
import full07 from "./static/images/fulls/07.jpg";
import thumb08 from "./static/images/thumbs/08.jpg";
import full08 from "./static/images/fulls/08.jpg";
import thumb09 from "./static/images/thumbs/09.jpg";
import full09 from "./static/images/fulls/09.jpg";
import thumb10 from "./static/images/thumbs/10.jpg";
import full10 from "./static/images/fulls/10.jpg";

const DEFAULT_IMAGES = [
  { id: "1", src: full01, thumbnail: thumb01 },
  { id: "1", src: full02, thumbnail: thumb02 },
  { id: "1", src: full03, thumbnail: thumb03 },
  { id: "1", src: full04, thumbnail: thumb04 },
  { id: "1", src: full05, thumbnail: thumb05 },
  { id: "1", src: full06, thumbnail: thumb06 },
  { id: "1", src: full07, thumbnail: thumb07 },
  { id: "1", src: full08, thumbnail: thumb08 },
  { id: "1", src: full09, thumbnail: thumb09 },
  { id: "1", src: full10, thumbnail: thumb10 },
];

const prices = [
  {
    header: "Private Sessions",
    price: "$150",
    description: "Supply fee included",
  },
  [],
  {
    header: "Group Sessions",
    price: "Price upon request",
    description: "Supply fee included",
  },
  [],
  {
    header: "Paint Nights",
    price: "Price upon request",
    description: "5 Person Minimum.",
  },
];

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className=".container-fluid">
          <div className="row">
            <header className="App-header">
              <h1 className="sitename">{config.siteName}</h1>
              <div className="tagline">
                <p3>{config.subTagline}</p3>
              </div>
              <div className="Info">
                <SiteInfo />
              </div>
            </header>
          </div>
          <div className="row">
            <div className="Gallery">
              <Gallery
                images={DEFAULT_IMAGES.map(({ id, src, thumbnail }) => ({
                  src,
                  thumbnail,
                }))}
              />
            </div>
          </div>
          <div className="row bottom">
            <div className="col">
              <div className="contactform">
                <p>
                  In my painting class the students will learn how to create new
                  colors, blend, shade and apply various brushing techniques to
                  fully master the painting medium.
                </p>
                <p>Hours of operation: 12-4pm</p>
                <p>Session length: 1 hour</p>
                <p>For more info or to book a session</p>
                <p>email us at: {config.email}</p>
                <p>All inquiries welcome!</p>
                <a href="https://www.instagram.com/vivid_hues/?hl=en">
                  <img className="instalogo" src={instalogo} alt="instalogo" />
                </a>
              </div>
            </div>
          </div>
          <div className="prices" style={{}}>
            {prices.map((price, i) => (
              <>
                {i % 2 == 0 ? (
                  <div style={{}}>
                    <h2
                      style={{
                        fontWeight: "bolder",
                        fontFamily: "meddon, cursive",
                      }}
                    >
                      {price.header}
                    </h2>
                    <p style={{ fontSize: "1.5rem" }}>{price.price}</p>
                    <p>{price.description}</p>
                    {/* {index < prices.length - 1 ? <hr class="spacer"/>: <></>} */}
                  </div>
                ) : (
                  <hr class="spacer" />
                )}
              </>
            ))}
          </div>
        </div>
        <span>Located at 10 Karsten drive, Suffern NY 10901</span>© 2024 Vivid
        Hues © 2024 Dalia Herskovitz
      </div>
    );
  }
}

export default App;
